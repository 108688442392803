  <template>
    <div id="app-redirect" >
      <div>
        <img width="250px" src="@/assets/img/insideBrasil.png" alt="" />
      </div>

      <div class="info-container">

        <h1 class="info-title">Aplicativo da Inside Brasil</h1>
        <p class="info-description">Agora, todo seu histórico na Inside é acessado apenas pelo nosso aplicativo. Baixe agora mesmo para ver suas pesquisas, os valores a receber e a possibilidade de ser remunerado por indicações para amigos</p>
      </div>

      <div class="button-container">
          <!--  <a target='_blank' href='https://www.apple.com/br/app-store' class='button-link'>
          <button class='button'>
            <img src='https://img.icons8.com/?size=100&id=95294&format=png&color=FFFFFF' alt="Apple Store ícone" class='button-icon'>
            Disponível na<br>Apple Store
          </button>
        </a> -->

          <a target='_blank' href='https://play.google.com/store/apps/details?id=br.com.inside.mobileApp' class='button-link'>
            <button class='button'>
              <img src='https://img.icons8.com/?size=100&id=22982&format=png&color=FFFFFF' alt="Google Play ícone"
                class='button-icon'>
              <b> Disponível na<br> Google Play</b>
            </button>
          </a>
        </div>
    </div>
  </template>

  <script>
  export default {
    name: "RedirectAppStore",
  }
  </script>

  <style>
    #app-redirect {
      width: 100%;
      height: 100vh;
      grid-area: banner;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      background-color: #221e1e;
    }

    
  .info-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    margin: 30px 0 20px 0;
    background-color: #7635dc;
    padding: 4.5rem;
    border-radius: 8px;
  }

  .info-title {
    margin: 0;
    color: #ffffff;
  }

  .info-description {
    margin: 0;
    color: #ffffff;
    max-width: 620px; 
    word-wrap: break-word; 
    line-height: 1.5; 
    font-size: 1.1rem;
  }

  .button-container {
    display: flex;
    justify-content: center;
  }

  .button-link {
    text-decoration: none;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(5, 225, 199, 0.894);
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
    text-align: center;
  }

  .button-icon {
    width: 24px;
    height: auto;
    margin-right: 10px;
  }

@media (max-width: 768px) {


  .info-container {
    padding: 2rem;
  }

  .info-title {
    font-size: 1.5rem; 
  }

  .info-description {
    font-size: 0.875rem; 
    max-width: 300px; 
  }

  .button {
    padding: 8px 16px; 
    font-size: 14px;
  }

  .button-icon {
    width: 20px;
  }
}

@media (max-width: 480px) {
  .info-container {
    padding: 1rem;
  }

  .info-title {
    font-size: 1.25rem; 
  }

  .info-description {
    font-size: 0.75rem; 
  }
}
  </style>
